(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;

function _default() {
  //header
  var headerSub = $('.sub');
  var headerSubMenu = $('.nav-item__sub_wrapper');
  var navbarToggler = $('.navbar-toggler');
  navbarToggler.click(function (e) {
    e.preventDefault();

    if ($(this).hasClass('collapsed')) {
      $('header').addClass('active');
      $('body').addClass('overflow-hidden');
    } else {
      setTimeout(function () {
        $('header').removeClass('active');
        $('body').removeClass('overflow-hidden');
      }, 250);
    }
  });
  var x = false;
  headerSub.click(function (e) {
    e.preventDefault();

    if (!x) {
      headerSubMenu.show();
      x = true;
    } else {
      headerSubMenu.hide();
      x = false;
    }
  });
}

},{}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;

function _default() {
  /*var _cookiePolicy = Cookies.get("cookiePolicy");
     if ($(".cookie-policy").length > 0 && _cookiePolicy != "true"){
         $('.cookie-policy').addClass('active');
     }
     $('.cookie-policy .cookie-close').click(function(e){
         e.preventDefault();
         $(this).parents('.cookie-policy').removeClass('active');
         Cookies.set("cookiePolicy", "true", {expires: 999});
     });*/
  //var year = new Date().getFullYear();
  //$('#year').html(year);
}

},{}],3:[function(require,module,exports){
"use strict";

var _cookie = _interopRequireDefault(require("./app/cookie"));

var _common = _interopRequireDefault(require("./app/common"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

$(document).ready(function () {
  (0, _cookie["default"])();
  (0, _common["default"])();
  resize();
  $(window).resize(function () {
    resize();
  });
  $('.main-categories .owl-carousel').owlCarousel({
    loop: false,
    margin: 15,
    responsiveClass: true,
    dots: true,
    nav: false,
    smartSpeed: 800,
    responsive: {
      0: {
        items: 1,
        stagePadding: 35
      },
      576: {
        items: 2
      }
    }
  });
  $('.main-common-owl').owlCarousel({
    loop: false,
    margin: 20,
    responsiveClass: true,
    dots: true,
    nav: true,
    smartSpeed: 800,
    responsive: {
      0: {
        items: 1,
        stagePadding: 35
      },
      576: {
        items: 2,
        margin: 15
      },
      991: {
        items: 4
      }
    }
  });

  $.fn.isInViewport = function () {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
  };

  var lastScrollTop = 0;
  $(window).scroll(function (event) {
    var st = $(this).scrollTop();
    var headerHeight = $('header').innerHeight();

    if (!$('.main-slider').isInViewport()) {
      if (st >= lastScrollTop) {
        $('header').removeClass('down').addClass('up');
      } else {
        $('header').removeClass('up').addClass('header-fixed down sub-header');
        $('.nav-item__sub').addClass('white-sub');
      }
    } else {
      $('header').removeClass('header-fixed down sub-header up');
      $('.nav-item__sub').removeClass('white-sub');
    }

    lastScrollTop = st;
  });
});

function resize() {
  var slideFigure = $('.main-slider figure');
  var windowHeight = $(window).height();
  slideFigure.height(windowHeight);
}

},{"./app/common":1,"./app/cookie":2}]},{},[3]);
